import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4'; // or 'react-ga'

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    console.log('ga sent--', location.pathname);
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);
};

export default usePageTracking;
