import { Flex, Image, Input, Text } from '@aws-amplify/ui-react';
import React, { useState } from 'react';
import '../styles/header.scss';
import logo from '../assets/info_logo.png';
import { useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();

  return (
    <header className="header">
      <Flex className="header_items-container">
        <Image
          onClick={() => navigate('/')}
          className="header_logo"
          alt="logo"
          src={logo}
          objectFit="initial"
          objectPosition="50% 50%"
          backgroundColor="initial"
        />
        <Text className="header_tagline">Stories of perosnalities and topics</Text>
      </Flex>
    </header>
  );
}

export default Header;
