import React from 'react';
import '../styles/sideAdvt.scss';
import { Text, Image } from '@aws-amplify/ui-react';
import collaboration from '../assets/collaboration.png';

function SideAdvt() {
  return (
    <div className="advt">
      <Image className="advt_image" alt="collaboration-image" src={collaboration} />
      <Text className="advt_text advt_text--title">Do you want to promote your business?</Text>
      <Text className="advt_text">Contact us at feed.infoon@gmail.com if you want to promote your business here!</Text>
    </div>
  );
}

export default SideAdvt;
