import { Flex, Text } from '@aws-amplify/ui-react';
import '../styles/footer.scss';

function Footer() {
  return (
    <Flex className="footer">
      <Flex className="footer_contents">
        <Text className="footer_text">© info.on 2024</Text>
        <Text className="footer_text--email">
          Please email to feed.infoon@gmail.com if you have any complaints, suggestions or feedback about the content.
        </Text>
      </Flex>
    </Flex>
  );
}

export default Footer;
