import './app.scss';
import Header from './components/Header';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import SideAdvt from './components/SideAdvt';
import ContentList from './components/ContentList';
import { BrowserRouter } from 'react-router-dom';
/* import { initializeApp } from 'firebase/app';
import { getDatabase, set, ref, push, child, get } from 'firebase/database';
import { useContext, useEffect } from 'react';
import { collection, addDoc, updateDoc, deleteDoc, getDoc, getDocs } from 'firebase/firestore';
import { initFirebase } from './firebase/conifg';
import { ContentfulContext } from './contentfulContext'; */

function App() {
  /*   const db = initFirebase();
   */
  /*  const dbRef = ref(getDatabase());
  const createData = async () => {
    try {
      const dbRef = ref(db, 'info');
      const newInfoRef = push(dbRef);
      await set(newInfoRef, {
        title: 'test2',
        text: 'long text2',
      });
    } catch (error) {
      console.log('could not create--', error);
    }
  }; */

  /*   const readData = async () => {
    try {
      get(child(dbRef, 'info')).then((snapshot) => {
        if (snapshot.exists()) {
          const data = Object.values(snapshot.val()).map((item: any) => ({
            title: item.title,
            text: item.text,
          }));
          console.log('test data--', data);
        } else {
          console.log('data is empty');
        }
      });
    } catch (error) {
      console.log('could not get--', error);
    }
  }; */

  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <div className="app_body">
          <SideAdvt />
          <Navigation />
          <ContentList />
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
