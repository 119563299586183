import { Text, Image } from '@aws-amplify/ui-react';
import { RichTextRenderer } from '../utils/richTextRenderer';

import { useLocation } from 'react-router-dom';

import '../styles/info.scss';

function Info() {
  const { state } = useLocation();

  const { name, infoTitle, imageUrl, info } = state.data;
  console.log('state--', state);

  return (
    <div className="info">
      <Text as="h1" className="info_name">
        {name}
      </Text>
      <Text as="h3" className="info_title">
        {infoTitle}
      </Text>
      {imageUrl && <Image className="info_image" src={imageUrl} alt={name} />}
      <RichTextRenderer richTextDocument={info} />
    </div>
  );
}

export default Info;
