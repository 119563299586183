import { Heading, Input, Text, Image } from '@aws-amplify/ui-react';
import '../styles/home.scss';
import { useContext, useState } from 'react';
import { ContentfulContext } from '../contentfulContext';
import InfoBar from '../components/InfoBar';
import collaboration from '../assets/collaboration.png';

function Home() {
  const [searchText, setSearchText] = useState<string>('');
  const { content, loading } = useContext(ContentfulContext);

  const filteredData = content.filter((item) => item.name.toLowerCase().includes(searchText));
  const highlightedContent = content.filter((item) => item.highlighted);

  return (
    <div className="home">
      <Heading className="home_titles" level={1}>
        {' '}
        WELCOME TO INFO-ON!!
      </Heading>{' '}
      <Text className="home_body-text">
        Info-on is an AI powered tool to find most important information on the most important personalities and topics
        that are presented like a story.
      </Text>
      <Text className="home_body-text home_body-text--center">Personality and topic highlights!</Text>
      <div className="home_highlight">
        {highlightedContent.map((item) => (
          <InfoBar key={item.name} name={item.name} infoTitle={item.title} info={item.info} imageUrl={item.images[0]} />
        ))}
      </div>
      <div className="home_business">
        <Image className="advt_image" alt="collaboration-image" src={collaboration} />
        <Text className="advt_text advt_text--title">Do you want to promote your business?</Text>
        <Text className="advt_text">
          Contact us at feed.infoon@gmail.com if you want to promote your business here!
        </Text>
      </div>
      <div className="home_search">
        <Text className="home_small-text">Search for your favourite personalities or topic!</Text>
        <Input
          onChange={(event) => setSearchText(event.currentTarget.value)}
          className="home_input"
          value={searchText}
          placeholder="Search for personality or topic"
        />
        {filteredData.map((item) => (
          <InfoBar
            small={true}
            key={item.name}
            name={item.name}
            infoTitle={item.title}
            info={item.info}
            imageUrl={item.images[0]}
          />
        ))}
      </div>
    </div>
  );
}

export default Home;
