import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ContentfulProvider } from './contentfulContext';
import ReactGA from 'react-ga4';
const GAID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID as string;

ReactGA.initialize(GAID); // Use your Measurement ID

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ContentfulProvider>
      <App />
    </ContentfulProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
