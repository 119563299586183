// src/ContentfulContext.tsx
import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { client } from './contentfulClient';
import { T_InfoFields } from './general.types';

interface ContentfulContextProps {
  content: T_InfoFields[];
  loading: boolean;
}

export const ContentfulContext = createContext<ContentfulContextProps>({
  content: [],
  loading: true,
});

interface ContentfulProviderProps {
  children: ReactNode;
}

export const ContentfulProvider: React.FC<ContentfulProviderProps> = ({ children }) => {
  const [content, setContent] = useState<T_InfoFields[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const transformData = (data: any) => {
    const baseData = data.items;
    const realData: T_InfoFields = baseData.map((item: any) => {
      return {
        name: item.fields.name,
        highlighted: item.fields.highlighted,
        info: item.fields.info,
        images: item.fields.images.map((item: any) => item.fields.file.url),
        tags: item.fields.tags,
        title: item.fields.title,
        type: item.fields.type,
      };
    });
    return realData;
  };

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const entries: any = await client.getEntries({ content_type: 'info' });

        const data: any = transformData(entries);
        setContent(data);
      } catch (error) {
        console.error('Error fetching data from Contentful:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, []);

  return <ContentfulContext.Provider value={{ content, loading }}>{children}</ContentfulContext.Provider>;
};
