import { Card, Image, Text } from '@aws-amplify/ui-react';
import React from 'react';
import '../styles/infoBar.scss';
import { ReactComponent as LinkIcon } from '../assets/link.svg';
import { useNavigate } from 'react-router-dom';

type T_InfoBarProps = {
  imageUrl: string;
  infoTitle: string;
  info: string;
  name: string;
  small?: boolean;
};

function InfoBar(props: T_InfoBarProps) {
  const navigate = useNavigate();
  const { imageUrl, infoTitle, name, small, info } = props;
  return (
    <Card
      onClick={() => navigate(`/${name.replace(/ /g, '-')}`, { state: { data: { imageUrl, infoTitle, name, info } } })}
      className={small ? 'info-bar info-bar--small' : 'info-bar'}
    >
      {!small && (
        <div className="info-bar_image-div">
          <Image className="info-bar_image" src={imageUrl} alt={name} />
        </div>
      )}
      <div className="info-bar_text-container">
        <Text className="info-bar_text info-bar_text--name">{name}</Text>
        <Text className="info-bar_text info-bar_text--title">{infoTitle}</Text>
      </div>
      <LinkIcon className="info-bar_icon" color="white" width={20} height={20} />
    </Card>
  );
}

export default InfoBar;
