import React, { useContext, useState } from 'react';
import '../styles/contentList.scss';
import '../styles/home.scss';
import { Input, Text, Image } from '@aws-amplify/ui-react';
import ReactGA from 'react-ga4';
import InfoBar from './InfoBar';
import { ContentfulContext } from '../contentfulContext';
import collaboration from '../assets/collaboration.png';

function ContentList() {
  const [searchText, setSearchText] = useState<string>('');
  const { content, loading } = useContext(ContentfulContext);

  const filteredData = content.filter((item) => item.name.toLowerCase().includes(searchText));

  const handleChange = (value: string) => {
    setSearchText(value);
    if (value.length > 4) {
      ReactGA.event('Search topic', {
        value: value,
        label: 'Search value',
      });
    }
  };

  return loading ? (
    <h3>LOADING</h3>
  ) : (
    <div className="contentlist">
      <Input
        onChange={(event) => handleChange(event.currentTarget.value)}
        className="contentlist_input"
        value={searchText}
        placeholder="Search for personality or topic"
      />
      <div className="contentlist_list">
        {filteredData.length < 1 && (
          <Text>
            Person or topic you are trying to search is not available, we will try to include the information in near
            future!
          </Text>
        )}
        {filteredData.map((item) => (
          <InfoBar
            small={true}
            key={item.name}
            name={item.name}
            infoTitle={item.title}
            info={item.info}
            imageUrl={item.images[0]}
          />
        ))}
      </div>
      <div className="contentlist_advt">
        <Image className="advt_image" alt="collaboration-image" src={collaboration} />
        <Text className="advt_text advt_text--title">Do you want to promote your business?</Text>
        <Text className="advt_text">
          Contact us at feed.infoon@gmail.com if you want to promote your business here!
        </Text>
      </div>
    </div>
  );
}

export default ContentList;
