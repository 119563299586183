import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from '../pages/Home';
import Info from '../pages/Info';
import usePageTracking from '../utils/usePageTracking';

function Navigation() {
  usePageTracking();
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:name/*" element={<Info />} />
    </Routes>
  );
}

export default Navigation;
